<template>
  <svg
    width="160px"
    height="68px"
    viewBox="0 0 160 68"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SIG036---registrace" transform="translate(-446.000000, -2388.000000)" stroke-width="2">
        <g id="Group-3" transform="translate(322.000000, 2305.000000)">
          <g id="Group-6" transform="translate(125.000000, 85.000000)">
            <path
              d="M0,59.7758605 C27.2407874,57.9844476 43.9547224,52.1169784 50.1418048,42.1734531 C59.4224284,27.2581651 38.9573117,15.9829177 25.0709024,23.4523992 C11.1844931,30.9218807 29.1104596,64.3273908 59.7302042,64.3273908 C80.1433673,64.3273908 99.7962979,55.9668063 118.688996,39.2456373"
              id="Path-4"
              stroke="#B4C1D6"
              stroke-dasharray="2,2"
            ></path>
            <g id="Group-4" transform="translate(107.000000, 0.000000)" stroke="#5E239E">
              <polygon
                id="Path-5"
                points="0.0813831782 25.4165614 17.3325282 29.7002676 20.6231972 41.6491856 19.4859426 30.5004125 36.5999495 34.2991193 49.7946098 0.100664762"
              ></polygon>
              <polyline
                id="Path-6"
                points="17.4329059 29.6191663 49.73429 0.120611156 19.480896 30.398758"
              ></polyline>
              <path d="M20.7387204,41.8929281 L27.8903441,32.3352405" id="Path-7"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PaperPlane',
};
</script>

<style lang="scss" scoped></style>
